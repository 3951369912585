import { Dates } from '@/components/area-report/filter-control/component/date-range';
import { RangeType } from '@/components/area-report/filter-control/component/range';

//Map mode
export enum MapMode {
  BROWSING = 'browsing',
  REGULATORY_PLANS = 'regulatory_plans',
  MUNICIPAL_PLANS = 'municipal_plans',
  NOISE_LEVELS = 'noise_levels',
  //Heatmaps new builds
  NEW_BUILDS_SQM_PRICE_HMAP = 'new_builds_sqm_price',
  NEW_BUILDS_PRICE_HMAP = 'new_builds_selling_price',
  NEW_BUILDS_DAYS_ON_MARKET_HMAP = 'new_builds_days_on_market',
  NEW_BUILDS_USABLE_AREA_HMAP = 'new_builds_usable_area',
  //Heatmaps units
  UNITS_SQM_PRICE_HMAP = 'units_sqm_price',
  UNITS_PRICE_HMAP = 'units_selling_price',
  UNITS_DAYS_ON_MARKET_HMAP = 'units_days_on_market',
  UNITS_USABLE_AREA_HMAP = 'units_usable_area',
}

//Map filters
export enum MapFilterType {
  CHECKBOX = 'checkbox',
  CHECKBOX_FLAG = 'checkboxFlag',
  RANGE = 'range',
  DATE_PICKER = 'datePicker',
  DATE_SELECTOR = 'dateSelector',
  TOGGLE = 'toggle',
  SELECT_AND_DATE = 'selectAndDate',
}

export enum MapDataSelected {
  PROJECTS_ON_THE_MARKET = 'projectsOnTheMarket',
  UNITS_ON_THE_MARKET = 'unitsOnTheMarket',
  UNITS_LISTED = 'unitsListed',
  UNITS_SOLD = 'unitsSold',
  UNITS_FOR_SALE_TODAY = 'unitsForSaleToday',
}

export enum NewProjectBasicFilters {
  NEW_PROJECT_ACTIVE = 'newProjectActive',
  NEW_PROJECT_DATA_SELECTED = 'newProjectDataSelected',
  NEW_PROJECT_UNIT_PROPERTY_TYPE = 'newProjectUnitPropertyType',
  NEW_PROJECT_UNIT_OWNERSHIP_TYPE = 'newProjectUnitOwnershipType',
  NEW_PROJECT_UNIT_SQM_PRICE = 'newProjectUnitSqmPrice',
  NEW_PROJECT_USABLE_SIZE = 'newProjectUsableSize',
  NEW_PROJECT_UNIT_ROOMS = 'newProjectUnitRooms',
  NEW_PROJECT_UNIT_SELLING_PRICE = 'newProjectUnitSellingPrice',
  NEW_PROJECT_UNIT_DAYS_ON_MARKET_MIN = 'newProjectUnitDaysOnMarketMin',
  NEW_PROJECT_CONSTRUCTION_YEAR = 'newProjectConstructionYear',
  NEW_PROJECT_FLOORS = 'newProjectFloors',
}

export const NewProjectFilter = {
  ...NewProjectBasicFilters,
};

export enum UsedUnitBasicFilters {
  USED_UNIT_ACTIVE = 'usedUnitActive',
  USED_UNIT_DATA_SELECTED = 'usedUnitDataSelected',
  USED_UNIT_USE_TYPE = 'usedUnitUseType',
  USED_UNIT_PROPERTY_TYPE = 'usedUnitPropertyType',
  USED_UNIT_OWNERSHIP_TYPE = 'usedUnitOwnershipType',
  USED_UNIT_SELLING_PRICE = 'usedUnitSellingPrice',
  USED_UNIT_SQM_PRICE = 'usedUnitSqmPrice',
  USED_UNIT_USABLE_AREA = 'usedUnitUsableArea',
  USED_UNIT_DAYS_ON_MARKET = 'usedUnitDaysOnMarket',
  USED_UNIT_ROOMS = 'usedUnitRooms',
  USED_UNIT_CONSTRUCTION_YEAR = 'usedUnitConstructionYear',
}

export enum NotIncludedFilter {
  NEW_PROJECT_ACTIVE = 'newProjectActive',
  NEW_PROJECT_DATA_SELECTED = 'newProjectDataSelected',
  USED_UNIT_ACTIVE = 'usedUnitActive',
  USED_UNIT_DATA_SELECTED = 'usedUnitDataSelected',
}

export enum FilterTypes {
  NewProjectBasicFilters = 'newProjectBasicFilters',
  UsedUnitBasicFilters = 'usedUnitBasicFilters',
}

interface MapFilterBase {
  id: string;
  label: string;
  apiParam?: string | string[];
  apiParams?: { [key: string]: string | string[] };
  tooltip?: string;
  description?: string;
  disabledTooltip?: string;
  showLabelWithTooltip?: boolean;
  values?: any;
  icon?: React.JSX.Element;
}

interface MapFilterCheckbox extends MapFilterBase {
  type: MapFilterType.CHECKBOX;
  values: {
    id: string;
    label: string;
    checked: boolean;
  }[];
}
interface MapFilterCheckboxFlag extends MapFilterBase {
  type: MapFilterType.CHECKBOX_FLAG;
  values: {
    id: string;
    label: string;
    checked: boolean;
  }[];
}
interface MapFilterToggle extends MapFilterBase {
  type: MapFilterType.TOGGLE;
  values: boolean;
}
interface MapFilterSelectAndDate extends MapFilterBase {
  type: MapFilterType.SELECT_AND_DATE;
  dataValues: string[];
  dateValues: {
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
  };
  dataSelected: string;
  minDate?: Date;
}

interface MapFilterRange extends MapFilterBase {
  type: MapFilterType.RANGE;
  values: RangeType;
  unit: string;
}

interface MapFilterDatePicker extends MapFilterBase {
  type: MapFilterType.DATE_PICKER;
  values: {
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
  };
  minDate?: Date;
}

interface MapFilterDateSelector extends MapFilterBase {
  type: MapFilterType.DATE_SELECTOR;
  values: {
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
  };
  minDate?: Date;
}

export type MapFilter =
  | MapFilterCheckbox
  | MapFilterCheckboxFlag
  | MapFilterRange
  | MapFilterDatePicker
  | MapFilterDateSelector
  | MapFilterToggle
  | MapFilterSelectAndDate;

export interface IMapFilters {
  [key: string]: MapFilter;
}
export type MapControlType = 'mapMode' | 'mapFilters' | 'none';

export type MapFilterValue =
  | string
  | string[]
  | RangeType
  | [string, string]
  | boolean
  | [string, Dates];

//Map state
export interface IMapState {
  mapControl: MapControlType;
  mapFilters: IMapFilters;
  mapMode: MapMode;
  setMapControl: (control: MapControlType) => void;
  setMapMode: (mode: MapMode) => void;
  onFilterChange?: (
    filterId: string,
    value: MapFilterValue,
    mapFilters: IMapFilters
  ) => void;
  setOnFilterChange: (
    onFilterChange?: (
      filterId: string,
      value: MapFilterValue,
      mapFilters: IMapFilters
    ) => void
  ) => void;
  updateToggleMapFilter: (filterId: string, value: boolean) => void;
  updateCheckboxMapFilter: (
    filterId: string,
    valueId: string,
    checked: boolean
  ) => void;
  updateRangeMapFilter: (filterId: string, value: RangeType) => void;
  updateDateRangeMapFilter: (filterId: string, dates: Dates) => void;
  updateSelectAndDateMapFilter: ({
    filterId,
    dataValue,
    dateValues,
  }: {
    filterId: string;
    dataValue?: string;
    dateValues?: Dates;
  }) => void;
  resetFilter: (filterId: string) => void;
  resetFilters: () => void;
}
