//This file reads the filter values from the store and prepares the query string for the map filters to be used for the endpoints. It also counts the number of filters applied and returns the count for each filter type. This is used in the map filter component to show the number of filters applied for each filter type.

import { useEffect, useMemo, useState } from 'react';
import { startOfDay } from 'date-fns';
import format from 'date-fns/format';

import { initialMapFilters, useMapStore } from '@/lib/store';
import {
  FilterTypes,
  IMapFilters,
  MapDataSelected,
  MapFilterType,
  NewProjectBasicFilters,
  NotIncludedFilter,
  UsedUnitBasicFilters,
} from '@/lib/types';

const filterMappings = {
  [FilterTypes.NewProjectBasicFilters]: NewProjectBasicFilters,
  [FilterTypes.UsedUnitBasicFilters]: UsedUnitBasicFilters,
};

const getFilterType = (filterId: string): FilterTypes | undefined => {
  return Object.entries(filterMappings).find(([_, filterEnum]) =>
    Object.values(filterEnum).includes(filterId)
  )?.[0] as FilterTypes;
};

const isNotIncludedFilter = (value: string) => {
  return Object.values(NotIncludedFilter).includes(value as NotIncludedFilter);
};

const prepareMapFiltersQueryString = (mapFilters: IMapFilters) => {
  let newProjectQs = '';
  let usedUnitQs = '';

  let newProjectBasicFilterCount = 0;
  let usedUnitBasicFilterCount = 0;

  for (const filterKey of Object.keys(mapFilters)) {
    const filter = mapFilters[filterKey];
    const initialFilter = initialMapFilters[filterKey]; // Get the initial filter values
    const filterType = getFilterType(filter.id);

    // Check if the current filter values differ from the initial values
    const hasFilterChanged =
      JSON.stringify(filter.values) !== JSON.stringify(initialFilter.values);

    switch (filter.type) {
      case MapFilterType.SELECT_AND_DATE:
        {
          if (!filter.dateValues.maxDate || !filter.dateValues.minDate) {
            break;
          }

          let dateBefore = format(filter.dateValues.maxDate, 'yyyy-MM-dd');
          let dateAfter = format(filter.dateValues.minDate, 'yyyy-MM-dd');

          if (filter.dataSelected === MapDataSelected.UNITS_FOR_SALE_TODAY) {
            dateBefore = format(startOfDay(new Date()), 'yyyy-MM-dd');
            dateAfter = format(startOfDay(new Date()), 'yyyy-MM-dd');
          }

          if (filter.apiParams) {
            const apiParam = filter.apiParams[filter.dataSelected];

            if (filter.dataSelected === MapDataSelected.UNITS_FOR_SALE_TODAY) {
              if (filterType === FilterTypes.NewProjectBasicFilters) {
                newProjectQs += `&new_project__unit__sale_status=FORSALE`;
              } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
                usedUnitQs += `&new_project__unit__sale_status=FORSALE`;
              }
            }

            if (filterType === FilterTypes.NewProjectBasicFilters) {
              newProjectQs += `&${apiParam[0]}=${dateBefore}&${apiParam[1]}=${dateAfter}`;
            } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
              usedUnitQs += `&${apiParam[0]}=${dateBefore}&${apiParam[1]}=${dateAfter}`;
            }
          }
        }
        break;

      case MapFilterType.CHECKBOX:
        filter.values.forEach((value) => {
          if (value.checked) {
            if (filterType === FilterTypes.NewProjectBasicFilters) {
              newProjectQs += `&${filter.apiParam as string}=${value.id}`;
            } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
              usedUnitQs += `&${filter.apiParam as string}=${value.id}`;
            }
          }
        });
        break;

      case MapFilterType.CHECKBOX_FLAG:
        filter.values.forEach((value, index) => {
          if (value.checked && filter.apiParam) {
            const apiParam = filter.apiParam[index];
            if (filterType === FilterTypes.NewProjectBasicFilters) {
              newProjectQs += `&${apiParam}=false`;
            } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
              usedUnitQs += `&${apiParam}=false`;
            }
          }
        });
        break;

      case MapFilterType.RANGE:
        if (filter.values[0] != null && filter.apiParam) {
          if (filterType === FilterTypes.NewProjectBasicFilters) {
            newProjectQs += `&${filter.apiParam[0]}=${filter.values[0]}`;
          } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
            usedUnitQs += `&${filter.apiParam[0]}=${filter.values[0]}`;
          }
        }
        if (filter.values[1] != null && filter.apiParam) {
          if (filterType === FilterTypes.NewProjectBasicFilters) {
            newProjectQs += `&${filter.apiParam[1]}=${filter.values[1]}`;
          } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
            usedUnitQs += `&${filter.apiParam[1]}=${filter.values[1]}`;
          }
        }
        break;

      case MapFilterType.DATE_PICKER:
      case MapFilterType.DATE_SELECTOR:
        if (filter.apiParam) {
          if (filter.values.minDate) {
            if (filterType === FilterTypes.NewProjectBasicFilters) {
              newProjectQs += `&${filter.apiParam[1]}=${format(
                filter.values.minDate,
                'yyyy-MM-dd'
              )}`;
            } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
              usedUnitQs += `&${filter.apiParam[1]}=${format(
                filter.values.minDate,
                'yyyy-MM-dd'
              )}`;
            }
          }
          if (filter.values.maxDate) {
            if (filterType === FilterTypes.NewProjectBasicFilters) {
              newProjectQs += `&${filter.apiParam[0]}=${format(
                filter.values.maxDate,
                'yyyy-MM-dd'
              )}`;
            } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
              usedUnitQs += `&${filter.apiParam[0]}=${format(
                filter.values.maxDate,
                'yyyy-MM-dd'
              )}`;
            }
          }
        }

        break;
    }

    // Increment counts based on whether the filter differs from the initial filter (not based on selection)
    if (hasFilterChanged && !isNotIncludedFilter(filter.id)) {
      if (filterType === FilterTypes.NewProjectBasicFilters) {
        newProjectBasicFilterCount++;
      } else if (filterType === FilterTypes.UsedUnitBasicFilters) {
        usedUnitBasicFilterCount++;
      }
    }
  }

  return {
    newProjectQs,
    usedUnitQs,
    newProjectFilterCount: newProjectBasicFilterCount,
    newProjectBasicFilterCount,
    usedUnitFilterCount: usedUnitBasicFilterCount,
    usedUnitBasicFilterCount,
  };
};

export const useMapFiltersQsAndCount = () => {
  const mapFilters = useMapStore((state) => state.mapFilters);

  const {
    newProjectQs,
    newProjectFilterCount,
    newProjectBasicFilterCount,
    usedUnitQs,
    usedUnitFilterCount,
    usedUnitBasicFilterCount,
  } = useMemo(() => prepareMapFiltersQueryString(mapFilters), [mapFilters]);

  return {
    mapFiltersQs: { newProjectQs, usedUnitQs },
    filterCount: {
      newProjectFilterCount,
      newProjectBasicFilterCount,
      usedUnitFilterCount,
      usedUnitBasicFilterCount,
      totalFilters: newProjectFilterCount + usedUnitFilterCount,
    },
  };
};

export const useModifiedFilters = () => {
  const [modifiedFilters, setModifiedFilters] = useState<{
    [key: string]: any;
  }>({});
  const mapFilters = useMapStore((state) => state.mapFilters);

  useEffect(() => {
    const updatedFilters: { [key: string]: unknown } = {};

    Object.keys(mapFilters).forEach((key) => {
      const current = mapFilters[key];
      const initial = initialMapFilters[key];

      if (current.apiParam) {
        if (current.type === MapFilterType.CHECKBOX) {
          const filteredValues = current.values
            .filter((item) => item.checked)
            .map((item) => item.id);

          if (filteredValues.length > 0) {
            updatedFilters[current.apiParam as string] = filteredValues;
          }
        } else if (current.type === MapFilterType.CHECKBOX_FLAG) {
          current.values.forEach((item, index) => {
            if (item.checked && current.apiParam) {
              updatedFilters[current.apiParam[index]] = 'false';
            }
          });
        } else if (
          current.type === MapFilterType.DATE_PICKER ||
          current.type === MapFilterType.DATE_SELECTOR
        ) {
          const minDateParam = current.apiParam[1];
          const maxDateParam = current.apiParam[0];

          if (current.values.minDate) {
            updatedFilters[minDateParam] = format(
              current.values.minDate,
              'yyyy-MM-dd'
            );
          }

          if (current.values.maxDate) {
            updatedFilters[maxDateParam] = format(
              current.values.maxDate,
              'yyyy-MM-dd'
            );
          }
        } else if (current.type === MapFilterType.RANGE) {
          const [minParam, maxParam] = current.apiParam;

          if (current.values[0] !== null) {
            updatedFilters[minParam] = current.values[0];
          }

          if (current.values[1] !== null) {
            updatedFilters[maxParam] = current.values[1];
          }
        } else if (
          JSON.stringify(current.values) !== JSON.stringify(initial.values)
        ) {
          if (current.values !== null) {
            updatedFilters[key] = current.values;
          }
        }
      } else if (current.apiParams) {
        if (current.type === MapFilterType.SELECT_AND_DATE) {
          let dateBefore = format(
            current.dateValues.maxDate as Date,
            'yyyy-MM-dd'
          );
          let dateAfter = format(
            current.dateValues.minDate as Date,
            'yyyy-MM-dd'
          );

          // Check if the dataSelected is UNITS_FOR_SALE_TODAY, and adjust dates
          if (current.dataSelected === MapDataSelected.UNITS_FOR_SALE_TODAY) {
            dateBefore = format(startOfDay(new Date()), 'yyyy-MM-dd');
            dateAfter = format(startOfDay(new Date()), 'yyyy-MM-dd');

            // Add the new_project__unit__sale_status=FORSALE parameter
            updatedFilters['new_project__unit__sale_status'] = 'FORSALE';
          }

          const apiParam = current.apiParams[current.dataSelected];
          updatedFilters[apiParam[0]] = dateBefore;
          updatedFilters[apiParam[1]] = dateAfter;
        }
      }
    });
    setModifiedFilters(updatedFilters);
  }, [mapFilters]);

  return modifiedFilters;
};
